@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .button-bar {
    gap: 2rem;
  }

  .resource-archive-container {
    @include desktop {
      margin-top: 3rem;
    }
  }
