@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .desktop-footer {
    --background: var(--ion-color-dark);
    --margin-top: 0px;
    --padding-top: 1.5rem;
    --padding-bottom: 0rem;
    --color: var(--ion-color-light);
    --text-size: var(--dib-font-size-3);

    background: var(--background);
    margin-top: var(--margin-top);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
  }

  .footer-site-nav {
    .nav-button {
      display: block;
      color: var(--color);
      text-decoration: none;
      padding: 0.6rem 0;

      &:hover {
        color: var(--dib-color-primary-medium);
      }
    }
  }

  //   .footer-column {
  //     width: 25%;
  //   }

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  a {
    color: var(--color);
    font-size: var(--text-size);
    text-decoration: none;
  }

  a:hover {
    color: var(--dib-color-primary-medium);
  }

  .main-footer-content {
    padding-bottom: 1rem;
  }

  .legal-bar {
    --text-size: var(--dib-font-size-3);

    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid var(--dib-color-dark-medium);
  }

  .legal-links-container {
    a {
      display: block;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: var(--dib-font-size-2);
    }
  }

  .social-media-container {
    display: flex;
    gap: 1rem;
  }

  .phone-icon {
    padding-left: 21px;
    padding-right: 21px;
  }

  .external-links-row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include breakpoint(desktop) {
      & > a {
        min-width: 210px;
      }
    }
  }

  .external-link-title {
    display: block;
    color: var(--dib-color-primary-medium);
    padding-bottom: 0.5rem;
  }

  .link-rows-container {
    border-top: 1px solid var(--dib-color-dark-medium);
  }

  .copyright-container {
    font-size: var(--dib-font-size-2);
  }
  .x-logo {
    width: 28.3px;
    height: 28.3px;
  }
