@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .main-layout-container {
    --main-content-background: var(--ion-color-light);

    @include desktop {
      --main-content-background: transparent;
    }
  }

  .panes-container {
    flex-direction: column;
    width: 100%;

    .pane-container {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    @include desktop {
      flex-direction: row;
      gap: 1rem;

      .pane-container,
      .calendar-container {
        padding: 1.5rem;
      }
    }
  }

  .calendar-container {
    min-width: 35%;
    @include desktop {
      --background-color: var(--ion-color-light);
      background-color: var(--background-color);
      border-radius: 10px;
    }
  }

  .sub-router-outlet {
    position: relative;
  }
