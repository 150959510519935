.calendar-overrides {
	&.vc-container {
		--vc-rounded: 0;
		--vc-rounded-lg: 0;
		--yellow-600: #CFA544;

		border: 0;
		background-color: transparent;
	}

	@include tablet {
		--vc-text-sm: 20px;
	}

	.vc-day {
		min-height: 48px;

		@include tablet {
			min-height: 64px;
		}
	}

	.vc-day-content {
		width: 32px;
		height: 32px;
	}

	.vc-highlight,
	.vc-day-content {
		@include tablet {
			width: 36px;
			height: 36px;
		}
	}

	.vc-header {
		padding-bottom: 0.75rem;

		.vc-title {
			background: transparent;
		}
	}

	.vc-arrow {
		background: transparent;
	}
}
