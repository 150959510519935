@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .back-button:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .back-container {
    border-bottom: 1px solid var(--ion-color-medium);
  }

  .back-arrow {
    transform: rotate(180deg);
    filter: brightness(0);
  }
