<template>
  <div class="masonry-container">
    <div
      class="masonry-column"
      v-for="columnIndex in columns"
      :key="columnIndex"
      :style="'width: calc(' + (1 / columns) * 100 + '% - var(--gap));'"
    >
      <template
        v-for="(item, itemIndex) of columnItems[columnIndex - 1]"
        :key="itemIndex + '' + columnIndex"
      >
        <div class="item">
          <slot :item="item" :index="itemIndex"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from "vue";

  export default defineComponent({
    props: {
      items: {
        type: Array,
        required: true,
      },
      columns: {
        type: Number,
        required: true,
        default: 4,
      },
      getEstimatedHeight: {
        type: Function,
        default: () => {
          return 1;
        },
      },
      balanceTolerance: {
        // as a percent of the average height
        type: Number,
        default: 0.6,
      }
    },
    setup(props) {
      const columnItems = computed(() => {
        const buckets: any[] = Array.from({ length: props.columns }, () => []);
        const bucketEstimates: number[] = Array.from(
          { length: props.columns },
          () => 0
        );

        const averageHeight =
          props.items.reduce((acc: number, item) => {
            return acc + props.getEstimatedHeight(item);
          }, 0) / props.items.length;

        let currentBucketIndex = 0;
        for (let i = 0; i < props.items.length; i++) {
          const item = props.items[i];
          const estimatedHeight = props.getEstimatedHeight(item);

          buckets[currentBucketIndex].push(item);
          bucketEstimates[currentBucketIndex] += estimatedHeight;

          // if current bucket is within 1/2 average height of the tallest bucket, move to the next bucket
          const maxBucketHeight = Math.max(...bucketEstimates);
          
          if (
            bucketEstimates[currentBucketIndex] >
            maxBucketHeight - averageHeight * props.balanceTolerance
          ) {
            currentBucketIndex = (currentBucketIndex + 1) % props.columns;
          }
        }

        return buckets;
      });
      return { columnItems };
    },
  });
</script>

<style scoped>
  .masonry-container {
    --gap: 16px;
    --justify-content: start;

    display: flex;
    flex-direction: row;
    justify-content: var(--justify-content);
    flex: 1 1 25%;
    gap: var(--gap);
  }

  .item {
    margin-bottom: var(--gap);
  }
</style>
