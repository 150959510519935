@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

.section-title {
  margin-bottom: 1.1rem;
  line-height: 1;
}

$card-width: 300px;

.card {
  --card-height: 200px;
  --card-width: #{$card-width};
  --padding-x: 1.5rem !important;
  --padding-y: 1.75rem !important;
}

.card-grid {
  --card-height: 200px;
  --card-width: #{$card-width};
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 1rem;
}
