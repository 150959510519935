@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .resource-card-header {
    height: 0;

    @media screen and (min-width: 416px) {
      height: 1.5rem;
    }
  }

  .resource-card-container {
    height: 100%;
  }

  .card-link {
    color: var(--dib-color-primary);
    text-decoration: underline;
  }
