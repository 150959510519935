@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .multi-select-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .filter-button {
    --border-color: var(--dib-color-medium);
    --active-border-color: var(--dib-color-primary);
    --active-background: var(--ion-color-primary);
    --active-color: var(--ion-color-light);
    
    min-width: 155px;

    &.active {
      --border-color: var(--active-border-color);
      --background: var(--active-background);
      --color: var(--active-color);

      color: var(--active-color);
    }
  }
