@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

.card-section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.card-section.department {
  background-color: rgba(255, 255, 255, 0.5);
}
