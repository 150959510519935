@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .pane-container {
    --background-color: var(--ion-color-light);
    background-color: var(--background-color);
  }

  @include breakpoint(desktop) {
    .pane-container {
      border-radius: 10px;
    }
  }
