@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  // TODO: fix popover animation
  .popover-container {
    --background: var(--ion-color-light);
  }

  .dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
  }

  .chevron-container {
    img,
    svg {
      transition: all 0.15s ease-out;
      transform: rotate(0deg);
    }

    &.open {
      img,
      svg {
        transform: rotate(180deg);
      }
    }
  }

  @media screen and (max-width: 767px) {
    .popover-container {
      --background: var(--ion-color-light);
      --height: unset;
      --min-height: unset;
      --max-height: unset;
      --width: unset;
      --min-width: unset;
      --max-width: unset;

      &::part(content) {
        bottom: 15px;
        left: calc(19px + var(--offset-x, 0px));
        right: calc(19px + var(--offset-x, 0px));
      }
    }
  }

  @include breakpoint(tablet) {
    .teset {
      color: red;
    }
  }

  @media screen and (min-width: 768px) {
    .popover-container {
      --min-width: 400px;
    }
  }
