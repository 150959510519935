@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .main-layout-container {
    --main-content-padding-bottom: 4rem;
    --main-content-mobile-padding-bottom: 0rem;
    --main-content-background: transparent;
  }

  .main-content {
    --header-height: 112px;
    --footer-height: 304.73px;

    padding-bottom: var(--main-content-mobile-padding-bottom);
    background: var(--main-content-background);

    @include desktop {
      padding-bottom: var(--main-content-padding-bottom);

      min-height: calc(100vh - var(--header-height) - var(--footer-height));
    }
  }
