@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";
@charset "utf-8";

// @import "@./../node_modules/bulma/sass/base/_all.sass";
// @import "@/../node_modules/bulma/sass/components/_all.sass";
// @import "@/../node_modules/bulma/sass/elements/_all.sass";
@import "@/../node_modules/bulma/sass/elements/container.sass";
@import "@/../node_modules/bulma/sass/components/card.sass";
@import "@/../node_modules/bulma/sass/components/tabs.sass";
// @import "@/../node_modules/bulma/sass/form/_all.sass";
@import "@/../node_modules/bulma/sass/grid/_all.sass";
@import "@/../node_modules/bulma/sass/helpers/_all.sass";
// @import "@/../node_modules/bulma/sass/layout/_all.sass";
// @import "@/../node_modules/bulma/sass/utilities/_all.sass";

// additional helpers
.has-text-weight-black {
    font-weight: $weight-black !important
}

// overrides


// .container {
//     @include desktop {
//         max-width: $desktop - (2 * $gap) !important;
//     }

//     @include widescreen {
//         max-width: $widescreen - (2 * $gap) !important;
//     }

//     @include fullhd {
//         max-width: 1500px !important;
//     }
// }


// additions

// add responsive spacing helpers
@each $property,
$shortcut in $spacing-shortcuts {

    @each $name,
    $value in $spacing-values {

        // All directions
        .#{$shortcut}-#{$name}-touch {
            @include touch {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-tablet {
            @include tablet {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-desktop {
            @include desktop {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-widescreen {
            @include widescreen {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-fullhd {
            @include fullhd {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-mobile {
            @include mobile {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-tablet-only {
            @include tablet-only {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-desktop-only {
            @include desktop-only {
                #{$property}: $value !important;
            }
        }

        .#{$shortcut}-#{$name}-widescreen-only {
            @include widescreen-only {
                #{$property}: $value !important;
            }
        }

        // Cardinal directions
        @each $direction,
        $suffix in $spacing-directions {
            .#{$shortcut}#{$suffix}-#{$name}-touch {
                @include touch {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-tablet {
                @include tablet {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-desktop {
                @include desktop {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-widescreen {
                @include widescreen {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-fullhd {
                @include fullhd {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-mobile {
                @include mobile {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-tablet-only {
                @include tablet-only {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-desktop-only {
                @include desktop-only {
                    #{$property}-#{$direction}: $value !important;
                }
            }

            .#{$shortcut}#{$suffix}-#{$name}-widescreen-only {
                @include widescreen-only {
                    #{$property}-#{$direction}: $value !important;
                }
            }
        }

        // Horizontal axis
        @if $spacing-horizontal !=null {
            .#{$shortcut}#{$spacing-horizontal}-#{$name}-touch {
                @include touch {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-tablet {
                @include tablet {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-desktop {
                @include desktop {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-widescreen {
                @include widescreen {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-fullhd {
                @include fullhd {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-mobile {
                @include mobile {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-tablet-only {
                @include tablet-only {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-desktop-only {
                @include desktop-only {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-horizontal}-#{$name}-widescreen-only {
                @include widescreen-only {
                    #{$property}-left: $value !important;
                    #{$property}-right: $value !important;
                }
            }
        }

        // Vertical axis
        @if $spacing-vertical !=null {
            .#{$shortcut}#{$spacing-vertical}-#{$name}-touch {
                @include touch {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-tablet {
                @include tablet {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-desktop {
                @include desktop {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-widescreen {
                @include widescreen {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-fullhd {
                @include fullhd {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-mobile {
                @include mobile {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-tablet-only {
                @include tablet-only {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-desktop-only {
                @include desktop-only {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }

            .#{$shortcut}#{$spacing-vertical}-#{$name}-widescreen-only {
                @include widescreen-only {
                    #{$property}-top: $value !important;
                    #{$property}-bottom: $value !important;
                }
            }
        }
    }
}

.swiper-wrapper {
    margin-bottom: 0 !important;
}