@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .article-text {
    display: inline;
  }

  .news-single {
    --main-content-background: var(--ion-color-light) !important;
  }

  .article-header-top-bar {
    border-bottom: 1px solid var(--ion-color-medium);
    margin-bottom: 1rem;
    padding-bottom: 0.25rem;
  }

  .featured-image {
    margin-bottom: 3rem;
  }

  @include breakpoint(desktop) {
    .featured-image {
      width: 100%;
    }
  }
