@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .tag-list {
    --tag-color: var(--dib-color-dark-medium);
    --tag-size: var(--dib-font-size-2);
  }

  .tag {
    color: var(--tag-color);
    font-size: var(--tag-size);
  }
