@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: var(--dib-color-primary-medium);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
