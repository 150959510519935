@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  a {
    border-bottom: 6px solid transparent;
  }
  a.active,
  a:hover {
    border-bottom: 6px solid var(--ion-color-dark);
  }
  .tabs {
    gap: 1.5rem;
  }

  .icon {
    height: 40px;
    width: auto;

    @include desktop {
      height: 44px;
    }
  }

  .tab-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include desktop {
      flex-direction: row;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tabs-lists {
    padding-inline-start: 0;
    justify-content: space-between;

    li {
      width: 33%;

      @include desktop {
        width: auto;
      }
    }

    @include desktop {
      justify-content: flex-start;
    }
  }
