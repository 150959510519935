// customize here
// https://bulma.io/documentation/customize/variables/

// initial variables
$size-1: 12px;
$size-2: 14px;
$size-3: 16px;
$size-4: 18px;
$size-5: 20px;
$size-6: 22px;
$size-7: 24px;
$size-8: 26px;
$size-9: 28px;
$size-10: 30px;
$size-11: 32px;
$size-12: 34px;
$family-sans-serif: 'Roboto', sans-serif;

// derived variables
$sizes: $size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7, $size-8, $size-9, $size-10, $size-11, $size-12;

// generic variables
$body-font-size: $size-4;

$weight-black: 900;

// undocumented variables?
$spacing-values: (
    "0": 0,
    "1": 0.25rem,
    "2": 0.5rem,
    "3": 1rem,
    "4": 1.5rem,
    "5": 2rem,
    "6": 3rem,
    "7": 4rem,
    "8": 5rem,
    "auto": auto
);

$gap: 32px;
$fullhd: 1500px + (2 * $gap);