@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .card-group {
    position: relative;
  }

  .card-group-title-and-link {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-group-title {
    font-weight: 700;
    line-height: 2.5;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .view-all-link {
    color: $grey-dark;
    font-weight: 400;
    font-size: 0.875rem;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .swiper {
    /* Added overflow: visible so that the drop shadows wouldn't get cut off, but there may be better ways of doing it. :shrug */
    overflow: visible;
  }

  .swiper-fade {
    position: absolute;
    top: -0.75rem;
    display: block;
    bottom: -1.25rem; // needs to be larger to cover whole shadow
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(244, 244, 244, 1) 1.5rem
    );
    z-index: 3;
    left: calc(-1 * var(--page-gutter));
    width: var(--page-gutter);
  }

  .swiper-wrapper {
    margin-bottom: 2rem;

    @include tablet() {
      margin-bottom: 3rem;
    }
  }

  .swiper-scrollbar {
    margin-bottom: -1rem;
  }

  .group-title-img {
    width: 2.5rem;
    margin-right: 1rem;
  }
