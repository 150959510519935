@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .card {
    --padding-x: 1.5rem;
    --padding-y: 2rem;
    --padding-x-mobile: 1.5rem;
    --padding-y-mobile: 2rem;

    position: relative;
    transition: background-color 0.1s ease-out;
    font-size: 0.875rem;
    display: block;
    cursor: pointer;
    box-shadow: none !important;

    min-width: 290px;

    width: var(--card-width, 80vw);
    height: 270px;

    @media screen and (min-width: 416px) {
      height: var(--card-height, 290px);
      width: var(--card-width, 368px);
    }
  }

  .card {
    border-radius: 10px;

    background-color: var(--card-color, rgb(255, 255, 255));
    border: 2px solid;
    border-color: transparent;

    transition: 0.3s ease-out background-color, 0.3s ease-out border-color;

    &:hover,
    &:focus {
      border-color: var(--dib-color-primary);
      background-color: var(--dib-color-primary-light);
    }

    .card-content {
      margin: 0;
      padding: var(--padding-y-mobile) var(--padding-x-mobile);
      height: 100%;
      overflow: hidden;

      @media screen and (min-width: 416px) {
        padding: var(--padding-y) var(--padding-x);
      }
    }
  }

  a.card {
    text-decoration: none;
  }
