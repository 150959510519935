@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .color-split-text {
    --highlight-color: var(--ion-color-primary);
    --font-weight: 700;
    --font-size: var(--dib-font-size-8);
  }

  span {
    font-weight: var(--font-weight);
    font-size: var(--font-size);
  }

  .highlighted {
    color: var(--highlight-color);

    &.bolded {
      font-weight: 700;
    }
  }
