@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .articles-container {
    background: var(--ion-color-light);
  }

  .filter-chips {
    ion-chip {
      border-radius: 3px;

      // &:first-child {
      //   margin-left: 0;
      // }
    }

    .clear-button {
      background-color: transparent;
      color: var(--ion-color-primary);
      text-decoration: underline;
    }
  }

  .filter-bar {
    background-color: var(--ion-color-light);

    @include breakpoint(desktop) {
      background-color: transparent;
      margin-top: 2rem;
    }
  }

  .filter-top-bar {
    border-bottom: 1px solid var(--dib-color-medium);

    @include breakpoint(desktop) {
      border-bottom: 1px solid var(--dib-color-dark-medium);
    }
  }

  .button.circle {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    background-color: var(--ion-color-light);
    color: var(--ion-color-primary);
    box-shadow: none;
    border: 1px solid var(--ion-color-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: $size-7;
    font-weight: bold;
    color: var(--ion-color-light);
    background-color: var(--ion-color-primary);

    &:hover,
    &:active {
      background-color: var(--dib-color-primary-dark);
    }
  }
