@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

/* Theme */
:root {
  --dib-color-dark: #3B3B3B;
  --dib-color-light: #FFFFFF;
  --dib-color-medium: #EEEEEC;
  --dib-color-dark-medium: #8B8B8B;
  --dib-color-primary-dark: #B68F3B;
  --dib-color-primary: #906E02;
  --dib-color-primary-medium: #CFA545;
  --dib-color-primary-light: #FCFAF4;

  --dib-font-size-12: #{$size-12};
  --dib-font-size-11: #{$size-11};
  --dib-font-size-10: #{$size-10};
  --dib-font-size-9: #{$size-9};
  --dib-font-size-8: #{$size-8};
  --dib-font-size-7: #{$size-7};
  --dib-font-size-6: #{$size-6};
  --dib-font-size-5: #{$size-5};
  --dib-font-size-4: #{$size-4};
  --dib-font-size-3: #{$size-3};
  --dib-font-size-2: #{$size-2};
  --dib-font-size-1: #{$size-1};

  --breakpoint-mobile: 768px;
  --breakpoint-tablet: 1023px;
  --breakpoint-desktop: 1024px;
  --breakpoint-widescreen: 1407px;
  --breakpoint-fullhd: 1408px;

  --ion-background-color: var(--ion-color-medium)
}

html {
  font-size: 16px !important;
}

*:not(html, body) {
  font-family: 'Roboto', sans-serif;
  font-size: var(--dib-font-size-4);
  color: var(--dib-color-dark);
  line-height: 1.3;
}

*:not(html, body, strong, em) {
  font-weight: 400;
}

a {
  color: var(--dib-color-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: #{$size-8};
  font-weight: 700;
}

h2 {
  font-size: #{$size-6};
  font-weight: 500;
}

h3 {
  font-size: #{$size-5};
  font-weight: 500;
}

.inner-container {
  margin: auto;
  max-width: 800px;
}

.icon-title {
  display: flex;
  align-items: center;

  span {
    font-size: $size-8;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0.4rem;
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.search-form-content button:active svg {
    stroke: black;
    fill: white;
}

@import "./v-calendar-overrides.scss";
