@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .tel-link {
    color: var(--ion-color-primary);
    text-decoration: none;
  }

  .it-support-section {
    @include widescreen {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
  }

  .icon {
    @include touch {
      max-width: 40px;
    }
  }

  .it-support-content {
    @include until-widescreen {
      width: 100%;
      justify-content: center;
      max-width: 25rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .support-link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: var(--dib-color-primary);
    }
  }

  .support-link:hover {
    h2 {
      color: var(--dib-color-primary);
    }
  }
