@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

.post-list-item {
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-medium);
  --link-color: var(--ion-color-primary);

  border-top-width: var(--border-width);
  border-top-style: var(--border-style);
  border-top-color: var(--border-color);
}

* > .post-list-item:last-child {
  border-bottom-width: var(--border-width);
  border-bottom-style: var(--border-style);
  border-bottom-color: var(--border-color);
}

.link {
  color: var(--link-color);
}

// inputs
$image-container-width: 250px;
$image-container-padding: 1rem;

// auto calcs
$image-width: calc(#{$image-container-width} - #{$image-container-padding} * 2);
$image-ratio: calc(9 / 16);
$image-height: calc(#{$image-width} * #{$image-ratio});

.image-container {
  padding: 0 $image-container-padding;
  width: $image-container-width;
  padding-top: 0.4rem;
}

.date-featured-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f1f1f1;
  height: $image-height;
  text-align: center;
}

.inner-date-featured-image {
  text-align: center;

  span {
    line-height: 0.95;
  }

  .day {
    font-size: 3rem;
    font-weight: 700;
  }
}
