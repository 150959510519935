@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .style-outlined.icon-button {
    --background-color: var(--dib-color-primary-light);
    --border-color: var(--dib-color-primary-medium);
    --border-width: 3px;
    --border-style: solid;
    --border-radius: 4px;

    &.large {
      --border-radius: 10px;

      @include widescreen {
        --icon-button-min-height: 100px;
        --icon-button-min-width: 330px;
      }
    }
  }

  .style-flat.icon-button {
    --background-color: var(--dib-color-light);
    --border-color: none;
    --border-width: 0px;
    --border-style: none;
    --border-radius: 2px;

    &.large {
      --border-radius: 8px;

      @include widescreen {
        --icon-button-min-height: 100px;
        --icon-button-min-width: 330px;
      }
    }
  }

  .style-transparent.icon-button {
    --background-color: transparent;
    --border-color: none;
    --border-width: 0px;
    --border-style: none;
    --border-radius: 0;
    --icon-button-min-height: 0px;
    --icon-button-min-width: 0px;

    &.large {
      --border-radius: 0;
      --icon-button-min-height: 0px;
      --icon-button-min-width: 0px;
    }
  }

  .icon-button {
    --padding-x: 1rem;
    --padding-y: 1rem;

    line-height: 1;
    padding: var(--padding-y) var(--padding-x);

    background: var(--background-color);
    border-color: var(--border-color);
    min-width: var(--icon-button-min-width, 0px);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-radius: var(--border-radius);
  }

  .icon-button.large {
    @include widescreen {
      min-height: var(--icon-button-min-height, 100px);
      min-width: var(--icon-button-min-width, 330px);
    }
  }

  button.active {
    background: var(--dib-color-primary-medium);
  }

  .layout-row {
    .icon-container {
      display: block;
      margin-right: 0.75rem;
    }
  }

  .text-container {
    display: block;
  }

  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
