@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .comments {
    margin-top: 1rem;
    padding-top: 2rem;
    border-top: 1px solid var(--ion-color-medium);
  }

  .comment {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--ion-color-medium);

    display: flex;
    gap: 1rem;

    &:last-child {
      border-bottom: none;
    }

    .middle-section {
      flex-grow: 1;
    }
  }

  .comment-author,
  .comment-author-name {
    line-height: 0.9;
  }

  .submit-button {
    --background: var(--dib-color-primary-medium);
  }

  .delete-button {
    --padding-start: 0.25rem;
    --padding-end: 0.25rem;
  }

  .pagination-button {
    --border-color: var(--dib-color-dark);
    &.button-disabled {
      --border-color: #aaa;
    }

    &.ion-activated {
      fill: transparent;
    }
  }
