@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .bottom-nav {
    --background: var(--ion-color-light);
  }

  .nav-button-container {
    display: flex;
    flex: 1 1 auto;
  }

  .nav-button-image {
    height: 25px;
  }

  .nav-button {
    --active-color: var(--ion-color-primary);

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: var(--ion-color-dark);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:hover {
      color: var(--ion-color-primary);
    }

    &.active {
      color: var(--ion-color-primary);
    }
  }
