<template>
  <div class="back-bar pt-2-touch pb-4-touch pb-5-desktop pt-4-desktop">
    <route-link class="back-link" :router-link="routerLink">
      <slot name="back-symbol">&#60;</slot>&nbsp;<slot>Back</slot>
    </route-link>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import RouteLink from "@/components/RouteLink.vue";

  export default defineComponent({
    components: {
      RouteLink,
    },
    props: {
      routerLink: {
        type: String,
        required: true,
      },
    },
    setup() {
      return {};
    },
  });
</script>

<style scoped>
.back-link {
    text-decoration: none;
}
</style>
