@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .day-container {
    font-size: 32px;
    color: var(--ion-color-primary);
    font-weight: 700;
    min-width: 102px;
  }

  .date-container {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
  }

  .calendar-list-item-container {
    border-bottom: 1px solid var(--ion-color-medium);

    .date-container {
      border-right: 1px solid var(--ion-color-medium);
    }

    &:last-child {
      border-bottom: none;
    }
  }
