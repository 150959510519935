@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

.title-bar {
  --background: var(--ion-color-dark) !important;
  --color: var(--ion-color-dark-contrast) !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.title-bar-wrapper {
  background: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}

.subtitle-bar {
  --background: var(--ion-color-light);
  min-height: 40px;
  padding-top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.subtitle-bar-wrapper {
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
}
.search-form-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .search-form-content {
    display: flex;
    align-items: center;
    background-color: #F1F1F1;
    border-radius: 5px;
    input {
      width: 20rem;
      font-size: 1rem;
      padding: 10px;
      border-radius: 5px;
      background-color: #F1F1F1;
      text-transform: none;
      border: none;
      &:focus {
        outline: 0;
      }
    }
    svg {
      display: flex;
      width: 1.5rem;
      margin-right: 7px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.search-button {
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  background-color: var(--ion-color-primary);
  color: white;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: var(--ion-color-primary-tint);
  }
}

.logo-container {
  max-width: 60%;
  display: block;
}

.subtitle-text {
  text-transform: uppercase;
}

.nav-button {
  --active-border-size: 6px;
  --active-border-color: var(--dib-color-primary-medium);
  --active-border-style: solid;

  color: var(--ion-color-dark-contrast);
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;
  display: block;
  min-height: calc(var(--min-height) - 2px);
  display: flex;
  align-items: center;
  font-size: var(--dib-font-size-4);
  text-transform: uppercase;

  border-bottom-width: var(--active-border-size);
  border-bottom-color: transparent;
  border-bottom-style: var(--active-border-style);
  border-top-width: var(--active-border-size);
  border-top-color: transparent;
  border-top-style: var(--active-border-style);

  padding-top: 1rem;
  padding-bottom: 1rem;

  &.active {
    border-bottom-width: var(--active-border-size);
    border-bottom-color: var(--active-border-color);
    border-bottom-style: var(--active-border-style);
  }
}

.nav-container {
  display: flex;
  flex: 1 1 auto;
}

.user-menu-item {
  &:hover {
    --background: #f9f9f9 !important;
    cursor: pointer;
  }
}

.avatar-container {
  @include desktop {
    margin-left: 0.5rem;
  }
}

.workbench-logo {
  max-width: 200px;
  height: auto;

  @include tablet {
    max-width: 300px;
  }

  @include widescreen {
    max-width: 400px;
  }
}
