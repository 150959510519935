@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .main-page-content {
    --icon-button-gap: 9px;

    padding-left: 2rem;
    padding-right: 2rem;

    @include widescreen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 3rem;
    }
  }

  .icon-button-row {
    gap: var(--icon-button-gap);
    flex-wrap: none;
    justify-content: space-between;

    .icon-button {
      width: 100%;
    }

    &.divided {
      .icon-button {
        border-right: 1px solid #d2d2d2;
      }

      .icon-button:last-child {
        border-right: none;
      }
    }
  }

  .icon-button-grid {
    gap: var(--icon-button-gap);
    flex-wrap: wrap;
    justify-content: space-between;

    .icon-button {
      height: 114px;
      width: calc(50% - calc(var(--icon-button-gap) / 2));
    }
  }

  .section {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include widescreen {
      margin: 0.5rem;
    }
  }

  .welcome-section {
    text-align: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .external-buttons {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    --icon-button-gap: 0;

    .icon-button {
      --padding-x: 0.5rem;
      --padding-y: 0rem;
    }

    .pane-container {
      @include until-widescreen {
        --background-color: transparent;
      }
    }

    @include widescreen {
      padding-top: 0rem;
      padding-bottom: 0rem;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }
  }

  .resource-button-row {
    .icon-button {
      @include widescreen {
        --icon-button-min-width: 0 !important;
        // same height as drive buttons if border is included
        --icon-button-min-height: 94px !important;
      }
    }

    .button-text {
      @include widescreen {
        font-weight: bold;
      }
    }
  }

  .news-link {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .route-link {
    text-decoration: none;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
    }
  }

  .news-section .post-list-item {
    &:first-of-type {
      border-top: 0;
    }

    &.is-last {
      border-bottom-width: var(--border-width);
      border-bottom-style: var(--border-style);
      border-bottom-color: var(--border-color);
    }
  }

  .resource-icons-section {
    @include widescreen {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
  }

  .news-section {
    @include widescreen {
      grid-column: 1 / 3;
      grid-row: 2 / 5;
    }
  }

  .events-section {
    @include widescreen {
      grid-column: 3 / 4;
      grid-row: 3 / 4;
    }
  }

  .external-buttons {
    @include widescreen {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
  }

  .it-support-section {
    @include widescreen {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
  }

  .resource-button-row {
    @include until-widescreen {
      gap: var(--icon-button-gap);
      flex-wrap: wrap;
      justify-content: space-between;

      .icon-button {
        height: 114px;
        width: calc(50% - calc(var(--icon-button-gap) / 2));
      }
    }
  }

  .it-support-pane {
    @include until-widescreen {
      --background-color: transparent;
    }
  }
