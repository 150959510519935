@import "@/styles/theme/bulma-variables.scss";
                @import "@/../node_modules/bulma/sass/utilities/_all.sass";

  .rich-text {
    overflow: auto;
    // clear fix
        &::after {
          content: "";
          clear: both;
          display: table;
        }

    img {
      @include desktop() {
        max-width: 50%;
        float: right;
        margin: 1rem 0rem 1rem 1rem;
      }
    }

    &,
    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    br,
    img {
      color: inherit;
      font-size: inherit;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      > * {
        font-weight: 700;
      }
    }

    h1 {
      > * {
        font-size: #{$size-8};
      }
    }

    h2 {
      > * {
        font-size: #{$size-6};
      }
    }

    h3 {
      > * {
        font-size: #{$size-5};
      }
    }

    p {
      display: block;
      margin-bottom: 2rem;
      line-height: 1.6;
    }

    a {
      color: var(--ion-color-primary);
      text-decoration: underline;

      > * {
        color: inherit;
      }
    }

    ul {
      li {
        line-height: 1.6;
      }
    }
  }
